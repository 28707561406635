import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _21b049dc = () => interopDefault(import('../pages/apie-mus/index.vue' /* webpackChunkName: "pages/apie-mus/index" */))
const _43de6375 = () => interopDefault(import('../pages/kontaktai/index.vue' /* webpackChunkName: "pages/kontaktai/index" */))
const _01a2e7fa = () => interopDefault(import('../pages/paslaugos/index.vue' /* webpackChunkName: "pages/paslaugos/index" */))
const _14701628 = () => interopDefault(import('../pages/privatumo-politika/index.vue' /* webpackChunkName: "pages/privatumo-politika/index" */))
const _7e4ccee6 = () => interopDefault(import('../pages/projektai/index.vue' /* webpackChunkName: "pages/projektai/index" */))
const _0027729c = () => interopDefault(import('../pages/paslaugos/_slug.vue' /* webpackChunkName: "pages/paslaugos/_slug" */))
const _7c962d9e = () => interopDefault(import('../pages/projektai/_slug.vue' /* webpackChunkName: "pages/projektai/_slug" */))
const _1f01a3fc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apie-mus",
    component: _21b049dc,
    name: "apie-mus"
  }, {
    path: "/kontaktai",
    component: _43de6375,
    name: "kontaktai"
  }, {
    path: "/paslaugos",
    component: _01a2e7fa,
    name: "paslaugos"
  }, {
    path: "/privatumo-politika",
    component: _14701628,
    name: "privatumo-politika"
  }, {
    path: "/projektai",
    component: _7e4ccee6,
    name: "projektai"
  }, {
    path: "/paslaugos/:slug",
    component: _0027729c,
    name: "paslaugos-slug"
  }, {
    path: "/projektai/:slug",
    component: _7c962d9e,
    name: "projektai-slug"
  }, {
    path: "/",
    component: _1f01a3fc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
